<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{ name: 'CabinetIndex' }" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Программа лояльности читателей</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container class="drag-container" v-if="!loadPage">
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card class="table-promo pa-4 mb-4">
             <router-link
              :to="{
                name: 'Page',
                params: {
                  slug: 'programma-loyalnosti-pravila',
                },
              }"
            >
              Программа лояльности Правила
            </router-link>

            <v-row v-if="!books || books.length == 0" align="center">
              <no-content
                route-name
                title="У вас нет книг учавствующих в программе лояльности, Вы можете добавить книгу в программу в настройках книги."
              ></no-content>
            </v-row>
            <v-data-table
              v-else
              :headers="headersTable"
              :items="books"
              :items-per-page="15"
              class=""
              item-key="id_table"
            >
              <template v-slot:item.book="{ item }">
                <router-link
                  :to="{ name: 'BookShow', params: { slug: item.book.slug } }"
                >
                  {{ item.book.title }}
                </router-link>
              </template>
              <template v-slot:item.percent="{ item }">
                {{ item.percent }}%
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  small
                  @click="editItem(item)"
                >
                  <v-icon small> $vuetify.icons.edit </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    valid: true,
    loading: false,
    headersTable: [
      {
        text: "Книга",
        align: "start",
        // sortable: false,
        value: "title",
      },
      { text: "Скидка", value: "loyalty" },
      { text: "", value: "actions", sortable: false, align: "end" },
    ],
  }),
  methods: {
    getBooks() {
      let app = this;
      app.loading = true;
      this.$store
        .dispatch("cabinet_books/getMyBooksLoyalty")
        .then(() => {
          app.loading = false;
        })
        .catch(() => {
          app.loading = false;
        });
    },
    editItem(item) {
     this.$router.push({
          name: 'CabinetBookEdit',
          params: { id: item.id },
          query: {
            tab : "loyalty"
          },
        });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      books: "cabinet_books/books_loyalty",
    }),
  },
  mounted() {
    this.getBooks();
  },
};
</script>