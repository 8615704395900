var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"tile":"","flat":"","elevation":"1"}},[_c('v-btn',{staticClass:"no-active",attrs:{"to":{ name: 'CabinetIndex' },"icon":""}},[_c('v-icon',[_vm._v("$vuetify.icons.arrow_left")])],1),_c('v-toolbar-title',[_vm._v("Программа лояльности читателей")]),_c('v-spacer')],1),(!_vm.loadPage)?_c('v-container',{staticClass:"drag-container"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"table-promo pa-4 mb-4"},[_c('router-link',{attrs:{"to":{
              name: 'Page',
              params: {
                slug: 'programma-loyalnosti-pravila',
              },
            }}},[_vm._v(" Программа лояльности Правила ")]),(!_vm.books || _vm.books.length == 0)?_c('v-row',{attrs:{"align":"center"}},[_c('no-content',{attrs:{"route-name":"","title":"У вас нет книг учавствующих в программе лояльности, Вы можете добавить книгу в программу в настройках книги."}})],1):_c('v-data-table',{attrs:{"headers":_vm.headersTable,"items":_vm.books,"items-per-page":15,"item-key":"id_table"},scopedSlots:_vm._u([{key:"item.book",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'BookShow', params: { slug: item.book.slug } }}},[_vm._v(" "+_vm._s(item.book.title)+" ")])]}},{key:"item.percent",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.percent)+"% ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" $vuetify.icons.edit ")])],1)]}}],null,false,2519255242)})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }